import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'


class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          <span className="image main"><img src={pic01} alt="" /></span>
          <p>We are a group of dedicated writers working across a variety of genres. We meet periodically to workshop stories, offer each other constructive criticism, and write together. Novels, short stories, podcasts, works for screen or stage--we love it all!</p>
          <p>Our membership is diverse, and we welcome all voices writing for the 21st century. 
          <br /><em>(That means a big</em> no <em>to fascists, racists, transphobes, homophobes, sexists, etc.)</em></p>

          <p><small>For the various parts that went into making this website, check out <a href="/attributions">this link.</a></small></p>
          {close}
        </article>

        <article id="rules" className={`${this.props.article === 'rules' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Works</h2>
          <span className="image main"><img src={pic02} alt="" /></span>
          <p><b>Our members are a work in progress. Here's what they've published so far:</b>
          </p>
          <p>
          <h5>Short stories:</h5>
          </p>
          <p>
          <h5>Novels:</h5>
          </p>
          <p>
          <h5>Scripts:</h5>
          </p>
          <p>
          <h5>Podcasts:</h5>
          </p>
          {close}
        </article>

        <article id="members" className={`${this.props.article === 'members' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Members</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <p>This page is blank. Some of the things you might expect to find here are a list of members, links to personal websites, a link to our private discord channel where we coordinate meet-ups and share stories, a method for people to apply for membership.</p>
          
          <p>But no. This page is blank.</p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true">
          <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="www.twitter.com/leonperniciaro" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="www.twitter.com/leonperniciaro" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="www.twitter.com/leonperniciaro" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="www.twitter.com/leonperniciaro" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main